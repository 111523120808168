/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import './theme/themes';

// framework component styles
@import '@nebular/theme/styles/globals';

@tailwind base;
@tailwind components;
@tailwind utilities;

// install the framework styles
@include nb-install() {
  @include nb-theme-global();
}

;

.ng2-pdf-viewer-container {
  overflow: hidden !important;
}

.page-title {
  margin-top: 0;
}

.error-message {
  font-size: 10px;
  padding-left: 20px;
  color: red;
}

.label-fixed.sc-ion-label-ios-h {
  -ms-flex: 0 0 150px;
  flex: 0 0 150px;
  width: 150px;
  min-width: 100px;
  max-width: 200px;
}

.raw-data-ocr {
  display: block;
  font-size: 0.9em;

  code {
    display: block !important;
    height: 30vh;

    span {
      display: inline;
    }
  }
}

.screen {
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --width: 1000px;
    --height: 800px;
  }
}

.view-model-class {
  &::part(content) {
    width: 100%;
    height: 100%;
  }
}

.notifications-popover {
  --width: fit-content;
}